import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Fade } from 'react-reveal';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class ProjectItem extends Component {
  render() {
    const { node, position } = this.props;
    let offset = 'odd';
    // add an offset to every odd project
    if (Math.abs(position % 2) === 1) {
      offset = 'even';
    }
    const sizes = `452px, 325px`;
    let hasYear = '';
    if (node.yearCompleted) {
      hasYear = 'has-year';
    }

    return (
      <Fade delay={350}>
        <div className={`project project-${offset} ${hasYear}`}>
          <AniLink fade className="db" to={`/projects/${node.slug}`}>
            <div className="project-image">
              <Img
                className="featured-img"
                fluid={{ ...node.featuredImage.fluid, sizes }}
                alt={node.featuredImage.title}
              />
            </div>
            <h4>{node.title}</h4>
            {node.yearCompleted && <span>{node.yearCompleted}</span>}
          </AniLink>
        </div>
      </Fade>
    );
  }
}

export default ProjectItem;

ProjectItem.propTypes = {
  position: PropTypes.number.isRequired,
  node: PropTypes.object,
};
