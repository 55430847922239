import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import ProjectItem from '../components/project-item';
import ContactForm from '../components/Forms/ContactForm';
import PageBanner from '../components/page-banner';

class ProjectsPage extends React.Component {
  render() {
    const ProjectEdges = get(this, 'props.data.allContentfulProject.edges');
    return (
      <Layout>
        <Header headerType="" />
        <SEO title="Projects" />
        <PageBanner />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <AniLink fade className="home" to="/">
                      Home
                      <span>/</span>
                    </AniLink>
                  </li>
                  <li>
                    <AniLink fade className="current" to="/projects">
                      Projects
                    </AniLink>
                  </li>
                </ul>
              </div>
              <h1>Projects</h1>
            </div>
            <div className="col-12 projects-grid">
              {ProjectEdges.map(({ node }, i) => (
                <ProjectItem node={node} key={node.slug} position={i} />
              ))}
            </div>
          </div>
        </div>
        <ContactForm
          heading="Start with a free quote!"
          subheading="One our team will be in contact to help you with your request. Obligation free."
          theme="grey"
          formType="project"
        />
      </Layout>
    );
  }
}

export default ProjectsPage;

export const commercialQuery = graphql`
  query {
    allContentfulProject(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          linkUrl
          category
          featuredImage {
            title
            fluid(maxWidth: 452, maxHeight: 325) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
